<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            min="2021-05"
            :allowed-dates="allowedMonths"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :total-items="31"
          class="px-1 pb-1"
          item-key="date"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr>
              <td class="text-xs-center">
                {{ props.item.day }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.activation }}
              </td>
              <td class="text-xs-center info--text">
                {{ props.item.updatePlan }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-center">
&nbsp;
              </td>
              <td class="text-xs-center info--text">
                {{ totalActivation }}
              </td>
              <td class="text-xs-center info--text">
                {{ totalUpdatePlan }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const reportTitle = 'Prihatin'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "Panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Prihatin', disabled: true,
        },
      ],
      items: [],
      headers: [
        {
          align: 'center',
          sortable: false,
          text: 'Day',
          value: 'day',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Activation',
          value: 'activation',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Update Plan',
          value: 'updatePlan',
        },
      ],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      showChart: false,
      title: reportTitle,
      totalActivation: 0,
      totalUpdatePlan: 0,
    }
  },
  computed: {
  },
  watch: {
    monthOf: function (val) {
      this.getPrihatinStat(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2021, 5, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getPrihatinStat (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getPrihatinStat.php', params)
        .then(function (response) {
          this.items = response.data.item
          this.totalActivation = response.data.totalActivation
          this.totalUpdatePlan = response.data.totalUpdatePlan
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
